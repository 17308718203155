<template>
  <div class="createMoveMemberEmailAccount">
    <div class="content">
      {{ $t("createMoveMemberEmailAccount_content") }}
    </div>
    <div class="button_box" v-if="!disabled">
      <div class="button_style l_button" v-waves @click.stop="createAccount">
        {{ $t("createMoveMemberEmailAccount_button_l") }}
      </div>
      <div class="button_style r_button" v-waves @click.stop="nextStep">
        {{ $t("createMoveMemberEmailAccount_button_r") }}
      </div>
    </div>
  </div>
</template>
<script>
import { setStep } from "@/api/newVersion/team";
export default {
  name: "CreateMoveMemberEmailAccount",
  data() {
    return {
      disabled: false,
    };
  },
  props: {
    // 当前流程进度
    actualCurrentStep: {
      type: Number,
      default: 0,
    },
    // 当前模块所属步骤
    showStep: {
      type: Number,
      default: 0,
    },
    // 已完成步骤
    completedSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  async mounted() {
    // 如果模块不是流程当前步骤，并且当前模块已完成，则开始回显已经填写过的信息
    if (
      this.showStep != this.actualCurrentStep &&
      this.completedSteps.includes(this.showStep)
    ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  },
  methods: {
    createAccount() {
      if (this.disabled) {
        return;
      }
      this.$emit("close");
      this.$store.commit("setOpenCreateMemberMethodDialog", true);
    },
    nextStep() {
      if (this.disabled) {
        return;
      }
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("createMoveMemberEmailAccount_confirm_text"),
        showDialog: true,
        number_of_buttons: 2,
        text_width: "413px",
        right_button_icon: "icon-check",
        confirmFn: async () => {
          const result = await setStep({
            step: "5",
            migrate: true,
          });
          if (result.code == 200 && result.data.data) {
            this.$emit("confirmCompletion");
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.createMoveMemberEmailAccount
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  .content
    font-size: 16px;
    line-height: 24px;
    color #000000;
    max-width: 700px;
  .button_box
    width: 700px;
    height: 48px;
    margin-top: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button_style
      width: 342px;
      height: 100%;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
      user-select: none;
    .l_button
      background: #52BD68;
      color: #FFFFFF;
      margin-right: 16px;
    .r_button
      border: 1px solid #52BD68;
      color: #52BD68;
</style>
