<template>
  <div class="manualContent" ref="dnsManualContent">
    <div class="manualContentHeader">
      {{ $t("domainVerification") }}
    </div>
    <div class="manualHeaderText">
      {{ $t("txtManualHeaderText") }}
    </div>
    <div class="manualContentHeader">
      {{ $t("verifyDNSConfig") }}
      <span class="manualContentHeaderTips">
        {{ $t("manualHeaderTitleTips") }}
      </span>
    </div>
    <div class="manualContentStyle">
      <div class="iconBlock">1</div>
      <div class="contentText">{{ $t("manualContent_1") }}</div>
    </div>
    <div class="manualContentInfoStyle">
      {{ $t("manualContentInfo_1") }}
    </div>
    <div class="manualContentStyle">
      <div class="iconBlock">2</div>
      <div class="contentText">{{ $t("manualContent_5") }}</div>
    </div>
    <template v-for="(item, index) in steps">
      <div class="manualContentInfoStyle">
        <span class="countBlock">{{ index + 1 }}、</span>{{ item.text }}
      </div>
      <div class="manualContentImageStyle">
        <img :src="item.image" is-viewer-image="true" @click="clickImage" />
      </div>
    </template>
    <div class="manualContentStyle">
      <div class="iconBlock">3</div>
      <div class="contentText">{{ $t("manualContent_6") }}</div>
    </div>
    <div class="manualImageStyle" v-if="completedImage != ''">
      <img :src="completedImage" is-viewer-image="true" @click="clickImage" />
    </div>
    <div class="manualContentStyle">
      <div class="iconBlock">4</div>
      <div class="contentText">{{ $t("manualContent_4") }}</div>
    </div>
    <div class="manualContentInfoStyle">
      {{ $t("manualContentInfo_10") }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      steps: [
        {
          text: this.$t("manualContentInfo_2"),
        },
        {
          text: this.$t("manualContentInfo_3"),
        },
        {
          text: this.$t("manualContentInfo_4"),
        },
        {
          text: this.$t("manualContentInfo_8"),
        },
        {
          text: this.$t("manualContentInfo_9"),
        },
      ],
      completedImage: "",
    };
  },
  created() {
    let language = localStorage.getItem("langCode") || 1;
    this.handlerStep(language);
  },
  methods: {
    clickImage() {
      try {
        const viewer = this.$viewer(this.$refs.dnsManualContent, {
          filter(image) {
            return image.getAttribute("is-viewer-image");
          },
          hidden: function() {
            viewer.destroy();
          },
        });
      } catch (error) {}
    },
    handlerStep(language) {
      if (parseInt(language) === 1) {
        this.steps = this.steps.map((item, index) => {
          switch (index) {
            case 0:
              item.image =
                "https://imfile.melinked.com/2023/12/f35c36aa-c62d-4eac-948f-1787f44e0d70.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 1:
              item.image =
                "https://imfile.melinked.com/2023/12/db46d1f5-85d2-4d31-887d-daf42bc4706f.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 2:
              item.image =
                "https://imfile.melinked.com/2023/12/f1029d68-a03b-4009-bc5f-01dca8eea236.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 3:
              item.image =
                "https://imfile.melinked.com/2023/12/0e60db8a-d2b1-40c7-bf60-2bc423e91ee5.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 4:
              item.image =
                "https://imfile.melinked.com/2023/12/e0435fd9-9ba7-476e-b3d9-f6a167ed4cdf.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
          }
          return item;
        });
        this.completedImage =
          "https://imfile.melinked.com/2023/12/c46da154-d882-4a91-b7ae-47f4519d3c1d.png?imageView2/0/interlace/1/format/png|imageslim";
      } else {
        this.steps = this.steps.map((item, index) => {
          switch (index) {
            case 0:
              item.image =
                "https://imfile.melinked.com/2023/12/bfe98c34-b40c-4ca1-9bb7-6408ea13dcec.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 1:
              item.image =
                "https://imfile.melinked.com/2023/12/f2c261c9-6956-4414-8b77-e3ba8c72124b.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 2:
              item.image =
                "https://imfile.melinked.com/2023/12/67914b84-ff19-4d97-9959-021b08841434.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 3:
              item.image =
                "https://imfile.melinked.com/2024/03/571714fd-7490-4326-931c-94c870582a81.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 4:
              item.image =
                "https://imfile.melinked.com/2024/01/11b90d5e-7466-434a-b514-63854c287d4f.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
          }
          return item;
        });
        this.completedImage =
          "https://tfile.melinked.com/2024/01/a6bcbf29-89b9-4c90-8027-6e855758aa22.png?imageView2/0/interlace/1/format/png|imageslim";
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.manualContent
  width: 100%;
  height: 100%;
  word-break: break-word;
  padding-bottom: 100px;
  .manualContentHeader
    width: 100%;
    font-size: 24px;
    color: #000000;
    text-align: left;
    .manualContentHeaderTips
      color: #858585;
      font-size: 18px;
  .manualHeaderText
    width: 100%;
    margin: 24px 0 48px 0;
    font-size: 18px;
    color: #000000;
    text-align: left;
  .manualContentStyle
    width: 100%;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #F0F0F0;
    .iconBlock
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #52BD68;
      text-align: center;
      line-height: 24px;
      font-size: 16px;
      color: #FFFFFF;
      display: inline-block;
      flex-shrink: 0;
      margin-right: 12px;
    .contentText
      min-width: 0;
      height: 24px;
      flex: 1;
      font-size: 18px;
      color: #000000;
  .manualContentInfoStyle
    width: 100%;
    padding-left: 36px;
    margin: 24px 0 10px 0;
    font-size: 18px;
    line-height: 24px;
    color: #8F8F8F;
    .countBlock
      display: inline-block;
      width: 30px;
  .manualContentImageStyle
    width: 50%;
    min-height: 0;
    margin-left: 66px;
    img
      width: 100%;
      display: block;
  .manualImageStyle
    width: 50%;
    min-height: 0;
    background: #FFFFFF;
    margin: 24px 0 0 36px;
    img
      width: 100%;
      display: block;
</style>
