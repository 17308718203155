var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "createMoveMemberEmailAccount" }, [
    _c("div", { staticClass: "content" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("createMoveMemberEmailAccount_content")) +
          "\n  "
      )
    ]),
    !_vm.disabled
      ? _c("div", { staticClass: "button_box" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "button_style l_button",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.createAccount.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("createMoveMemberEmailAccount_button_l")) +
                  "\n    "
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "button_style r_button",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.nextStep.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("createMoveMemberEmailAccount_button_r")) +
                  "\n    "
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }