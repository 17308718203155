<template>
  <div class="configureEmailMigrationParams">
    <div class="title">{{ $t("configureEmailMigrationParams_title_1") }}:</div>
    <div class="table_style table_1">
      <div class="table_row">
        <div class="row_title">
          {{ $t("originalEmailServiceProvider") }}
        </div>
        <div class="row_content">
          <el-select
            v-model="formData.serviceName"
            @change="changeServiceName"
            :disabled="disabled"
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.mailServer"
              :label="item.serviceName"
              :value="item.serviceName"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table_row">
        <div class="row_title">
          {{ $t("originalEmailLinkAgreement") }}
        </div>
        <div class="row_content">
          <el-input v-model="formData.protocol" readonly></el-input>
        </div>
      </div>
      <div class="table_row">
        <div class="row_title">
          {{ $t("incomingMailServer") }}
        </div>
        <div class="row_content">
          <div class="row_input">
            <el-input
              v-model="formData.mailServer"
              :disabled="disabled"
            ></el-input>
          </div>
          <div class="row_inner_content">
            <div class="inner_title">
              {{ $t("port") }}
            </div>
            <div class="inner_input">
              <el-input v-model="formData.port" :disabled="disabled"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <span
        class="clickBlock"
        @click.stop="changeSSL"
        :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
      >
        <i class="icon">
          <img
            class="img_1"
            v-if="useSSL"
            :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
            :src="
              require('../../../../../assets/images/teamManagement/SSL_icon.png')
            "
          />
          <img
            class="img_2"
            v-else
            :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
            :src="
              require('../../../../../assets/images/teamManagement/no_SSL_icon.png')
            "
          />
        </i>
        {{ $t("configureEmailMigrationParams_title_2") }}
      </span>
    </div>
    <div class="title">{{ $t("configureEmailMigrationParams_title_3") }}:</div>
    <div class="table_style table_2">
      <div class="table_row">
        <div class="row_title">
          {{ $t("configureEmailMigrationParams_title_4") }}
        </div>
        <div class="row_content">
          <el-input v-model="originalAccount" :disabled="disabled">
            <template slot="append">
              @{{ $store.state.team.verifyDomainDialog.domain }}
            </template>
          </el-input>
        </div>
      </div>
      <div class="table_row">
        <div class="row_title">
          {{ $t("configureEmailMigrationParams_title_5") }}
        </div>
        <div class="row_content">
          <el-input
            v-model="originalPassword"
            :disabled="disabled"
            show-password
          ></el-input>
        </div>
      </div>
    </div>
    <div class="button_box" v-if="!disabled">
      <div
        class="button_style"
        v-waves
        @click="startVerification"
        :style="{
          cursor: canStartVerification ? 'pointer' : 'not-allowed',
          background: canStartVerification ? '#52BD68' : '#D9D9D9',
        }"
      >
        {{ $t("startVerification") }}
      </div>
      <div
        class="button_style r_button"
        v-waves
        @click="stopMigration"
        v-if="!onceAgain"
      >
        {{ $t("stopMigration") }}
      </div>
    </div>
    <div class="failedBlock" v-if="validationFailed">
      <div class="failedHeader">
        <img
          class="failedIcon"
          :src="
            require('../../../../../assets/images/teamManagement/failed.png')
          "
        />
        <div class="failedText">
          {{ $t("configureEmailMigrationParams_failed_title") }}
        </div>
      </div>
      <div class="failedContent">
        {{ $t("configureEmailMigrationParams_failed_content") }}
      </div>
    </div>
  </div>
</template>
<script>
import {
  startTransferTask,
  getTransferTask,
  abandonVerificationDomain,
} from "@/api/newVersion/team";
import { encryptData, decryptData } from "@/utils/aes_email";
export default {
  name: "ConfigureEmailMigrationParams",
  data() {
    return {
      formData: {
        serviceName: "",
        protocol: "",
        mailServer: "",
        port: "",
      },
      originalAccount: "",
      originalPassword: "",
      validationFailed: false,
      useSSL: false,
      disabled: false,
    };
  },
  props: {
    // 再次进入邮箱迁移
    onceAgain: {
      type: Boolean,
      default: false,
    },
    // 当前流程进度
    actualCurrentStep: {
      type: Number,
      default: 0,
    },
    // 当前模块所属步骤
    showStep: {
      type: Number,
      default: 0,
    },
    // 已完成步骤
    completedSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    canStartVerification() {
      let flag = true;
      for (let i in this.formData) {
        if (this.globalTrim(this.formData[i].toString()) == "") {
          flag = false;
          break;
        }
      }
      if (
        this.globalTrim(this.originalAccount) == "" ||
        this.globalTrim(this.originalPassword) == ""
      ) {
        flag = false;
      }
      return flag;
    },
    selectOptions() {
      const lang = localStorage.getItem("langCode") || 1;
      return [
        {
          serviceName: "Gmail",
          protocol: "IMAP",
          mailServer: "imap.gmail.com",
          port: 993,
        },
        {
          serviceName: "Outlook",
          protocol: "IMAP",
          mailServer: "outlook.office365.com",
          port: 993,
        },
        {
          serviceName: "Yahoo Mail",
          protocol: "IMAP",
          mailServer: "imap.mail.yahoo.com",
          port: 993,
        },
        {
          serviceName: "AOL Mail",
          protocol: "IMAP",
          mailServer: "imap.aol.com",
          port: 993,
        },
        {
          serviceName: "iCloud Mail",
          protocol: "IMAP",
          mailServer: "imap.mail.me.com",
          port: 993,
        },
        {
          serviceName: lang == 1 ? "腾讯企业邮箱" : "Tencent Exmail",
          protocol: "IMAP",
          mailServer: "imap.exmail.qq.com",
          port: 993,
        },
        {
          serviceName: lang == 1 ? "网易企业邮箱" : "Netease Enterprise Mail",
          protocol: "IMAP",
          mailServer: "imap.qiye.163.com",
          port: 993,
        },
        {
          serviceName: lang == 1 ? "阿里云企业邮箱" : "Alibaba Cloud Mail",
          protocol: "IMAP",
          mailServer: "imap.qiye.aliyun.com",
          port: 993,
        },
        {
          serviceName: "Zoho Mail",
          protocol: "IMAP",
          mailServer: "imap.zoho.com",
          port: 993,
        },
        {
          serviceName: this.$t("otherMail"),
          protocol: "IMAP",
          mailServer: "",
          port: 0,
        },
      ];
    },
  },
  async mounted() {
    // 如果模块不是流程当前步骤，并且当前模块已完成，则开始回显已经填写过的信息
    if (
      this.showStep != this.actualCurrentStep &&
      this.completedSteps.includes(this.showStep)
    ) {
      this.disabled = true;
      await this.getTransferTask();
    } else {
      this.disabled = false;
    }
  },
  methods: {
    changeSSL() {
      if (this.disabled) {
        return;
      }
      this.useSSL = !this.useSSL;
    },
    async getTransferTask() {
      const result = await getTransferTask();
      if (result.code == 200) {
        this.useSSL = result.data.data.useSSL;
        this.formData.mailServer = result.data.data.host;
        this.formData.port = result.data.data.port;
        this.formData.serviceName = result.data.data.serviceName;
        this.formData.protocol = "IMAP";
        try {
          const token = JSON.parse(decryptData(result.data.data.mailToken));
          this.originalAccount = token.u;
          this.originalPassword = token.p;
        } catch (error) {}
      } else {
        this.$message.error(result.message);
      }
    },
    // 开始验证
    async startVerification() {
      if (!this.canStartVerification) {
        this.$message.error(this.$t("configureEmailMigrationParams_tips_1"));
        return;
      }
      const createdToken = {
        u: this.originalAccount,
        p: this.originalPassword,
      };
      const realTime = await this.getRealTime();
      createdToken.u = createdToken.u.toLowerCase();
      createdToken.t = realTime;
      let data = {
        host: this.formData.mailServer,
        mailToken: encryptData(JSON.stringify(createdToken)),
        port: this.formData.port,
        serviceName: this.formData.serviceName,
        useSSL: this.useSSL,
      };
      const result = await startTransferTask(data);
      if (result.code == 200) {
        this.$emit("confirmCompletion");
      } else if (result.code == 178134) {
        this.$message({
          type: "error",
          message: this.$t("migrate_tips_2"),
        });
        this.validationFailed = true;
        this.$nextTick(() => {
          this.$emit("scrollToTheBottom");
        });
      } else if (result.code == 178131 || result.code == 502) {
        this.$message({
          type: "error",
          message: this.$t("migrate_tips_3"),
        });
        this.validationFailed = true;
        this.$nextTick(() => {
          this.$emit("scrollToTheBottom");
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        this.validationFailed = true;
        this.$nextTick(() => {
          this.$emit("scrollToTheBottom");
        });
      }
    },
    // 停止搬家
    stopMigration() {
      if (this.disabled) {
        return;
      }
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("configureEmailMigrationParams_tips_3"),
        showDialog: true,
        number_of_buttons: 2,
        text_width: "413px",
        right_button_icon: "icon-check",
        confirmFn: async () => {
          const result = await abandonVerificationDomain();
          if (result.code == 200) {
            this.$emit("abandonVerification");
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
    changeServiceName() {
      if (this.formData.serviceName == this.$t("otherMail")) {
        this.formData.mailServer = "";
        this.formData.port = "";
        this.formData.protocol = "IMAP";
      } else {
        this.selectOptions.forEach((item) => {
          if (item.serviceName == this.formData.serviceName) {
            this.formData.mailServer = item.mailServer;
            this.formData.port = item.port;
            this.formData.protocol = item.protocol;
            this.useSSL = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.configureEmailMigrationParams
  width: 100%;
  overflow: hidden;
  padding-top: 30px;
  .title
    width: 100%;
    color: #292D32;
    font-size: 16px;
    margin: 30px 0 25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    .clickBlock
      display: inline-block;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .icon
        display: inline-block;
        width: 22px;
        height: 21px;
        margin-right: 8px;
        position: relative;
        .img_1
          width: 100%;
          height: 100%;
        .img_2
          width: 18.9px;
          height: 18.9px;
          position: absolute;
          bottom: 0;
          left: 0;
  .table_1
    height: 145px;
  .table_2
    height: 97px;
    width: 588px !important;
  .table_style
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    .table_row
      height: 48px;
      width: 100%;
      border-bottom: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      .row_title
        height: 100%;
        width: 266px;
        flex-shrink: 0;
        background: #F5F5F5;
        border-right: 1px solid #D9D9D9;
        line-height: 48px;
        text-align: center;
        padding:0 15px;
        overflow: hidden;
        color: #292D32;
        user-select: none;
      .row_content
        flex: 1;
        min-width: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep .el-select
          width: 100%;
          height: 100%;
          .el-select__caret
            color: #666666;
            font-size: 20px;
          .el-input__suffix
            right: 20px;
        :deep .el-input
          width: 100%;
          height: 100%;
          .el-input__inner
            width: 100%;
            height: 100%;
            border: none;
            font-size: 16px;
            color: #8D8C8C;
        .row_input
          height: 100%;
          flex: 1;
          min-width: 0;
        .row_inner_content
          width: 240px;
          flex-shrink: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .inner_title
            height: 100%;
            width: 85px;
            flex-shrink: 0;
            background: #F5F5F5;
            border-left: 1px solid #D9D9D9;
            border-right: 1px solid #D9D9D9;
            line-height: 48px;
            text-align: center;
            color: #292D32;
            user-select: none;
          .inner_input
            flex: 1;
            min-width: 0;
            height: 100%;
  .button_box
    height: 49px;
    width: 550px;
    display: flex;
    margin-top: 42px;
    align-items: center;
    justify-content: space-between;
    .button_style
      width: 266px;
      height: 49px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 18px;
      user-select: none;
      background: #FFFFFF;
    .r_button
      border: 1px solid #52BD68;
      color: #52BD68;
  .failedBlock
    margin-top: 30px;
    width: 100%;
    min-height: 20px;
    border-radius: 6px;
    border: 1px solid #ED6868;
    background: rgba(237, 104, 104, 0.05);
    padding-bottom: 11px;
    .failedHeader
      height: 24px;
      margin-top: 24px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .failedIcon
        width: 24px;
        height: 24px;
        vertical-align: middle;
      .failedText
        padding-left: 12px;
        color: #FF0000;
        font-size: 16px;
        line-height: 24px;
        vertical-align: middle;
    .failedContent
      padding: 12px 60px 0 60px;
      width: 100%;
      overflow: hidden;
      font-size: 16px;
      color: #000000;
      margin: 8px 0 24px 0;
      word-break: break-word;
</style>
