var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dialogLoading,
          expression: "dialogLoading"
        }
      ],
      attrs: {
        visible: _vm.dialogVisible,
        top: "0",
        "z-index": "1000",
        "custom-class": _vm.customClass,
        "destroy-on-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255,255,255,0.3)"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        "before-close": _vm.beforeClose
      }
    },
    [
      [
        _vm.showBlockStep == 0
          ? _c("div", { staticClass: "initialHeader" }, [
              _c("i", {
                staticClass: "iconStyle iconfont_Me icon-x",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.close.apply(null, arguments)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm.showBlockStep == 1
          ? _c("div", { staticClass: "methodSelectionHeader" }, [
              _c("i", {
                staticClass: "iconStyle iconfont_Me icon-x",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.close.apply(null, arguments)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm.showBlockStep == 2
          ? _c("div", { staticClass: "stepHeader" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.teamEmailActivationMethod == 0
                        ? _vm.$t("domainVerification")
                        : _vm.$t("mailboxMigrate")
                    ) +
                    "——" +
                    _vm._s(_vm.domainText) +
                    "\n      "
                )
              ]),
              _c("div", { staticClass: "headerOperate" }, [
                _vm.sizeType == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "buttonOuter",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeSize(1)
                          }
                        }
                      },
                      [_c("SizeButton")],
                      1
                    )
                  : _vm._e(),
                _vm.sizeType == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "buttonOuter",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeSize(0)
                          }
                        }
                      },
                      [_c("SizeButton", { attrs: { buttonType: "full" } })],
                      1
                    )
                  : _vm._e(),
                _c("div", {
                  staticClass: "icon-x iconfont_Me iconStyle buttonOuter",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.close.apply(null, arguments)
                    }
                  }
                })
              ])
            ])
          : _vm._e()
      ],
      _vm.showBlockStep == 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.domainLoading,
                  expression: "domainLoading"
                }
              ],
              staticClass: "initialContent",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255,0.3)"
              }
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("\n      " + _vm._s(_vm.$t("addDomain")) + "\n    ")
              ]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-input",
                    {
                      class: _vm.domainVerifyFailed ? "errorInput" : "",
                      model: {
                        value: _vm.domainText,
                        callback: function($$v) {
                          _vm.domainText = $$v
                        },
                        expression: "domainText"
                      }
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _c("img", {
                          style: {
                            width: "28px",
                            height: "28px"
                          },
                          attrs: {
                            src: require("../../../../assets/images/teamManagement/icon_domain.png")
                          }
                        })
                      ])
                    ],
                    2
                  ),
                  _vm.domainVerifyFailed
                    ? _c("div", { staticClass: "failedText" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.domainTips == 0
                                ? _vm.$t("domainVerificationFailed")
                                : _vm.domainTips == 1
                                ? _vm.$t("domainErrorText")
                                : _vm.domainTips == 2
                                ? _vm.$t("domainErrorText_1")
                                : ""
                            ) +
                            "\n      "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      style: {
                        background: _vm.initial_disabled ? "#E5E5E5" : "",
                        border: _vm.initial_disabled ? "1px solid #E5E5E5" : ""
                      },
                      attrs: {
                        type: "primary",
                        disabled: _vm.initial_disabled
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goMethodSelection.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont_Me icon-check iconStyle"
                      })
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.showBlockStep == 1
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.initialLoading,
                  expression: "initialLoading"
                }
              ],
              staticClass: "methodSelectionContent",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255,0.3)"
              }
            },
            [
              _c("span", { staticClass: "tipsText" }, [
                _vm._v(
                  _vm._s(_vm.$t("methodSelectionTips", { n: _vm.domainText }))
                )
              ]),
              _c("div", { staticClass: "buttonBox" }, [
                _c(
                  "div",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    staticClass: "buttonStyle cancellation",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.methodSelectionFn(0)
                      }
                    }
                  },
                  [_c("i", { staticClass: "iconStyle iconfont_Me icon-x" })]
                ),
                _c(
                  "div",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    staticClass: "buttonStyle confirm",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.methodSelectionFn(1)
                      }
                    }
                  },
                  [_c("i", { staticClass: "iconStyle iconfont_Me icon-check" })]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.showBlockStep == 2
        ? _c(
            "div",
            {
              ref: "stepContent",
              staticClass: "stepContent",
              style: { "overflow-y": _vm.expandManual ? "hidden" : "auto" }
            },
            [
              _vm.expandManual
                ? _c("div", { staticClass: "manualBlock" }, [
                    _c(
                      "div",
                      { staticClass: "innerContent" },
                      [
                        _c("div", { staticClass: "headerBlock" }, [
                          _c("i", {
                            staticClass: "iconfont_Me icon-return iconStyle",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.closeManual.apply(null, arguments)
                              }
                            }
                          })
                        ]),
                        _c("TXTManual", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.expandTXTManual,
                              expression: "expandTXTManual"
                            }
                          ]
                        }),
                        _c("DNSManual", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.expandDNSManual,
                              expression: "expandDNSManual"
                            }
                          ]
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "stepBarBox" },
                [
                  _c("StepBar", {
                    attrs: {
                      steps: _vm.handlerViews,
                      showStep: _vm.showStep,
                      active: _vm.actualCurrentStep,
                      completedSteps: _vm.completedSteps,
                      incompleteSteps: _vm.incompleteSteps
                    },
                    on: { selectStep: _vm.selectStep }
                  })
                ],
                1
              ),
              _vm.calculateView
                ? _c(
                    "div",
                    { staticClass: "contentBox" },
                    [
                      _c(_vm.calculateView, {
                        tag: "component",
                        attrs: {
                          showStep: _vm.showStep,
                          onceAgain: _vm.onceAgain,
                          actualCurrentStep: _vm.actualCurrentStep,
                          completedSteps: _vm.completedSteps,
                          domainText: _vm.domainText,
                          domainInfos: _vm.domainInfos,
                          method: _vm.teamEmailActivationMethod
                        },
                        on: {
                          readTXTManual: _vm.readTXTManual,
                          readDNSManual: _vm.readDNSManual,
                          confirmCompletion: _vm.confirmCompletion,
                          scrollToTheBottom: _vm.scrollToTheBottom,
                          abandonVerification: _vm.abandonVerification,
                          close: _vm.close
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }