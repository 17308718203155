var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "stepBar", staticClass: "stepBar" },
    [
      _vm._l(_vm.steps, function(item) {
        return [
          _c(
            "div",
            {
              key: item.index,
              staticClass: "stepBlock",
              attrs: { title: item.title },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.selectStep(item.step)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "stepIcon",
                  class: [
                    _vm.active == item.step
                      ? "activeStyle"
                      : _vm.completedSteps.includes(item.step)
                      ? "completedStyle"
                      : "notActiveStyle"
                  ]
                },
                [
                  _vm.completedSteps.includes(item.step)
                    ? _c("i", {
                        staticClass: "iconfont_Me icon-check iconStyle"
                      })
                    : _c("span", { staticClass: "stepNumber" }, [
                        _vm._v(_vm._s(item.index + 1))
                      ])
                ]
              ),
              _c(
                "div",
                {
                  class: [
                    "stepNameText",
                    _vm.showStep == item.step
                      ? "activeText"
                      : _vm.completedSteps.includes(item.step) ||
                        _vm.active == item.step
                      ? "completedText"
                      : "notActiveText"
                  ],
                  style: {
                    "word-break": _vm.langCode != 1 ? "keep-all" : "break-all"
                  }
                },
                [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
              )
            ]
          ),
          item.index != _vm.steps.length - 1
            ? _c("div", {
                staticClass: "stepLine",
                style: { width: _vm.stepLineWidth + "px" }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }