var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "replaceOrCancelDomainByOriginalServiceProvider" },
    [
      _c("div", { staticClass: "content_2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("replaceOrCancelDomainByOriginalServiceProvider_content_1")
            ) +
            "\n  "
        )
      ]),
      _c("div", { staticClass: "content_2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("replaceOrCancelDomainByOriginalServiceProvider_content_2")
            ) +
            "\n  "
        )
      ]),
      _c("div", { staticClass: "content_2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(
                "replaceOrCancelDomainByOriginalServiceProvider_content_3",
                {
                  n: _vm.domainText
                }
              )
            ) +
            "\n  "
        )
      ]),
      _c("div", { staticClass: "content_2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(
                "replaceOrCancelDomainByOriginalServiceProvider_content_4",
                {
                  n: _vm.domainText
                }
              )
            ) +
            "\n  "
        )
      ]),
      _c("div", { staticClass: "button_box" }, [
        _c(
          "div",
          {
            directives: [{ name: "waves", rawName: "v-waves" }],
            staticClass: "button_style",
            style: { cursor: _vm.disabled ? "not-allowed" : "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.finish.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("finishMigration")) + "\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }