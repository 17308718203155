var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dnsManualContent", staticClass: "manualContent" },
    [
      _c("div", { staticClass: "manualContentHeader" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("domainVerification")) + "\n  ")
      ]),
      _c("div", { staticClass: "manualHeaderText" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("txtManualHeaderText")) + "\n  ")
      ]),
      _c("div", { staticClass: "manualContentHeader" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("verifyDNSConfig")) + "\n    "),
        _c("span", { staticClass: "manualContentHeaderTips" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("manualHeaderTitleTips")) + "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "manualContentStyle" }, [
        _c("div", { staticClass: "iconBlock" }, [_vm._v("1")]),
        _c("div", { staticClass: "contentText" }, [
          _vm._v(_vm._s(_vm.$t("manualContent_1")))
        ])
      ]),
      _c("div", { staticClass: "manualContentInfoStyle" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("manualContentInfo_1")) + "\n  ")
      ]),
      _c("div", { staticClass: "manualContentStyle" }, [
        _c("div", { staticClass: "iconBlock" }, [_vm._v("2")]),
        _c("div", { staticClass: "contentText" }, [
          _vm._v(_vm._s(_vm.$t("manualContent_5")))
        ])
      ]),
      _vm._l(_vm.steps, function(item, index) {
        return [
          _c("div", { staticClass: "manualContentInfoStyle" }, [
            _c("span", { staticClass: "countBlock" }, [
              _vm._v(_vm._s(index + 1) + "、")
            ]),
            _vm._v(_vm._s(item.text) + "\n    ")
          ]),
          _c("div", { staticClass: "manualContentImageStyle" }, [
            _c("img", {
              attrs: { src: item.image, "is-viewer-image": "true" },
              on: { click: _vm.clickImage }
            })
          ])
        ]
      }),
      _c("div", { staticClass: "manualContentStyle" }, [
        _c("div", { staticClass: "iconBlock" }, [_vm._v("3")]),
        _c("div", { staticClass: "contentText" }, [
          _vm._v(_vm._s(_vm.$t("manualContent_6")))
        ])
      ]),
      _vm.completedImage != ""
        ? _c("div", { staticClass: "manualImageStyle" }, [
            _c("img", {
              attrs: { src: _vm.completedImage, "is-viewer-image": "true" },
              on: { click: _vm.clickImage }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "manualContentStyle" }, [
        _c("div", { staticClass: "iconBlock" }, [_vm._v("4")]),
        _c("div", { staticClass: "contentText" }, [
          _vm._v(_vm._s(_vm.$t("manualContent_4")))
        ])
      ]),
      _c("div", { staticClass: "manualContentInfoStyle" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("manualContentInfo_10")) + "\n  ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }