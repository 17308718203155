<template>
  <div class="stepBar" ref="stepBar">
    <template v-for="item in steps">
      <div
        class="stepBlock"
        :key="item.index"
        :title="item.title"
        @click.stop="selectStep(item.step)"
      >
        <div
          class="stepIcon"
          :class="[
            active == item.step
              ? 'activeStyle'
              : completedSteps.includes(item.step)
              ? 'completedStyle'
              : 'notActiveStyle',
          ]"
        >
          <i
            class="iconfont_Me icon-check iconStyle"
            v-if="completedSteps.includes(item.step)"
          ></i>
          <span class="stepNumber" v-else>{{ item.index + 1 }}</span>
        </div>
        <div
          :class="[
            'stepNameText',
            showStep == item.step
              ? 'activeText'
              : completedSteps.includes(item.step) || active == item.step
              ? 'completedText'
              : 'notActiveText',
          ]"
          :style="{ 'word-break': langCode != 1 ? 'keep-all' : 'break-all' }"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        class="stepLine"
        :style="{ width: stepLineWidth + 'px' }"
        v-if="item.index != steps.length - 1"
      ></div>
    </template>
  </div>
</template>
<script>
export default {
  name: "StepBar",
  props: {
    // 步骤数据
    steps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 当前进度步骤
    active: {
      type: Number,
      default: 0,
    },
    // 当前展示步骤
    showStep: {
      type: Number,
      default: 0,
    },
    // 已完成步骤
    completedSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 未完成步骤
    incompleteSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      stepLineWidth: 80,
      langCode: 1,
    };
  },
  mounted() {
    this.langCode = localStorage.getItem("langCode") || 1;
    this.calculateElementStyle();
  },
  methods: {
    // 计算元素样式
    async calculateElementStyle() {
      let stepBlockWidth = 0;
      this.$refs.stepBar.querySelectorAll(".stepBlock").forEach((item) => {
        stepBlockWidth += item.offsetWidth;
      });
      let defaultWidth = 900;
      if (this.steps.length == 2) {
        defaultWidth = 732;
      }
      if (
        stepBlockWidth + (this.steps.length - 1) * (this.stepLineWidth + 10) <
        defaultWidth
      ) {
        this.stepLineWidth =
          (defaultWidth - stepBlockWidth) / (this.steps.length - 1) - 10;
      }
      this.$refs.stepBar.style.width = `${stepBlockWidth +
        (this.steps.length - 1) * (this.stepLineWidth + 10)}px`;
      if (
        this.$refs.stepBar.parentNode.offsetWidth <
        this.$refs.stepBar.offsetWidth
      ) {
        this.$refs.stepBar.parentNode.style.overflowX = "auto";
        this.$refs.stepBar.parentNode.style.justifyContent = "flex-start";
      } else {
        this.$refs.stepBar.parentNode.style.overflowX = "hidden";
        this.$refs.stepBar.parentNode.style.justifyContent = "center";
      }
    },
    selectStep(step) {
      this.$emit("selectStep", step);
    },
  },
};
</script>
<style lang="stylus" scoped>
.stepBar
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  .stepBlock
    height: 100%;
    min-width: 100px;
    max-width: 130px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .stepIcon
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      margin-right: 8px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      .stepNumber
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        font-weight: 500;
    .completedStyle
      background: transparent;
      border: 1px solid #52BD68;
      .iconStyle
        color: #52BD68;
        font-weight: 500;
    .activeStyle
      background: #52BD68;
    .notActiveStyle
      background: #B3B3B3;
    .stepNameText
      line-height: 14px;
      font-size: 12px;
      vertical-align: middle;
      flex: 1;
    .activeText
      color: #52BD68;
    .completedText
      color: #292D32;
    .notActiveText
      color: #B3B3B3;
  .stepLine
    height: 1px;
    background: #D9D9D9;
    margin: 0 5px;
    flex-shrink: 0
</style>
