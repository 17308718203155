<template>
  <div class="replaceOrCancelDomainByOriginalServiceProvider">
    <div class="content_2">
      {{ $t("replaceOrCancelDomainByOriginalServiceProvider_content_1") }}
    </div>
    <div class="content_2">
      {{ $t("replaceOrCancelDomainByOriginalServiceProvider_content_2") }}
    </div>
    <div class="content_2">
      {{
        $t("replaceOrCancelDomainByOriginalServiceProvider_content_3", {
          n: domainText,
        })
      }}
    </div>
    <div class="content_2">
      {{
        $t("replaceOrCancelDomainByOriginalServiceProvider_content_4", {
          n: domainText,
        })
      }}
    </div>
    <div class="button_box">
      <div
        class="button_style"
        v-waves
        @click.stop="finish"
        :style="{ cursor: disabled ? 'not-allowed' : '' }"
      >
        {{ $t("finishMigration") }}
      </div>
    </div>
  </div>
</template>
<script>
import { setStep, updateTransferTask } from "@/api/newVersion/team";
export default {
  name: "ReplaceOrCancelDomainByOriginalServiceProvider",
  data() {
    return {
      disabled: false,
    };
  },
  props: {
    domainText: {
      type: String,
      default: "",
    },
    // 当前流程进度
    actualCurrentStep: {
      type: Number,
      default: 0,
    },
    // 当前模块所属步骤
    showStep: {
      type: Number,
      default: 0,
    },
    // 已完成步骤
    completedSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    if (
      this.showStep != this.actualCurrentStep &&
      this.completedSteps.includes(this.showStep)
    ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  },
  methods: {
    async finish() {
      if (this.disabled) {
        return;
      }
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t(
          "replaceOrCancelDomainByOriginalServiceProvider_tips"
        ),
        showDialog: true,
        number_of_buttons: 2,
        text_width: "387px",
        confirmFn: async () => {
          const result_1 = await setStep({
            step: "8",
            migrate: true,
          });
          if (result_1.code == 200 && result_1.data.data) {
            this.$emit("confirmCompletion");
          } else {
            this.$message({
              type: "error",
              message: result_1.message,
            });
          }
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.replaceOrCancelDomainByOriginalServiceProvider
  width: 100%;
  overflow: hidden;
  padding-top: 80px
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  .content_2
    width: 100%;
    font-size: 16px;
    color: #000;
    text-align: left;
    line-height: 24px;
  .button_box
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .button_style
      height: 100%;
      width: 340px;
      background: #52BD68;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      cursor: pointer;
      user-select: none;
</style>
