<template>
  <div class="verifyDNSContent">
    <div class="contentHeader">
      {{ $t("verifyDNSContent_1", { n: domainText }) }}
      <span class="specialStyle" @click.stop="readDNSverification">{{
        $t("DNSConfiguration")
      }}</span
      >:
    </div>
    <div class="contentText">
      1、{{ $t("verifyContent_2", { n: domainText }) }}
    </div>
    <div class="contentText">
      2、{{ $t("verifyDNSContent_2", { n: domainText }) }}
    </div>
    <div class="contentTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#F5F5F5',
          'font-weight': '400',
          color: '#858585',
          'font-size': '14px',
        }"
        :row-style="{
          color: '#000000',
          'font-size': '14px',
        }"
        :cell-style="setcellStyle"
      >
        <el-table-column
          prop="column_title"
          :label="''"
          width="180"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_1"
          :label="$t('tableHeader_1')"
          width="180"
          align="center"
          :resizable="false"
        >
          <template slot-scope="{ row }">
            <div
              :style="{
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
              }"
            >
              <div :style="{ 'word-break': 'break-word' }">
                {{ row.content_1.text || row.content_1 }}
              </div>
              <i
                v-show="row.content_1.copy"
                class="el-icon-document-copy"
                :style="{
                  color: '#52bd68',
                  'font-size': '18px',
                  'margin-left': '6px',
                  cursor: 'pointer',
                }"
                @click.stop="startCopy(row.content_1.text)"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content_2"
          :label="$t('tableHeader_2')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_3"
          :label="$t('tableHeader_3')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_4"
          :label="$t('tableHeader_4')"
          align="center"
          :resizable="false"
        >
          <template slot-scope="{ row }">
            <div
              :style="{
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
              }"
            >
              <div :style="{ 'word-break': 'break-word' }">
                {{ row.content_4.text }}
              </div>
              <i
                v-show="row.content_4.copy"
                class="el-icon-document-copy"
                :style="{
                  color: '#52bd68',
                  'font-size': '18px',
                  'margin-left': '6px',
                  cursor: 'pointer',
                }"
                @click.stop="startCopy(row.content_4.text)"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content_5"
          :label="$t('tableHeader_5')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="contentText">3、{{ $t("verifyContent_5") }}</div>
    <div class="dividerLine"></div>
    <div class="buttonBox" v-if="!disabled">
      <div
        class="buttonStyle startVerify"
        @click.stop="startVerify"
        :style="{
          'min-width': isVerifying ? '496px' : '240px',
        }"
      >
        <template v-if="!isVerifying">
          {{ $t("verifyDNSConfig") }}
        </template>
        <template v-else>
          <div class="wiatingText">{{ $t("nowVerifying") }}</div>
          <img
            class="wiatingImg"
            :src="
              require('../../../../../assets/images/teamManagement/refresh.png')
            "
          />
        </template>
      </div>
      <div
        class="buttonStyle cancelVerify"
        @click.stop="stopVerify"
        v-if="!isVerifying"
      >
        {{ $t("stopVerifiy") }}
      </div>
    </div>
    <div class="failedBlock" v-if="validationFailed">
      <div class="failedHeader">
        <img
          class="failedIcon"
          :src="
            require('../../../../../assets/images/teamManagement/failed.png')
          "
        />
        <div class="failedText">
          {{ $t("failedTitle") }}
        </div>
      </div>
      <div class="failedContent">
        <template v-for="(item, index) in domain_failedContents">
          <div class="failedItem" :key="index">
            <div class="failedTitle">
              <span :style="{ display: 'inline-block', width: '25px' }"
                >{{ index + 1 }}、</span
              >{{ item.title }}
            </div>
            <div class="failedText">
              {{ item.content }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {
  verificationDomain,
  abandonVerificationDomain,
} from "@/api/newVersion/team";
export default {
  props: {
    domainText: {
      type: String,
      default: "",
    },
    domainInfos: {
      type: Array,
      default: [],
    },
    // 当前流程进度
    actualCurrentStep: {
      type: Number,
      default: 0,
    },
    // 当前模块所属步骤
    showStep: {
      type: Number,
      default: 0,
    },
    // 已完成步骤
    completedSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 验证方式
    method: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 表格数据
      tableData: [
        {
          column_title: "MX",
          content_1: "",
          content_2: "MX",
          content_3: this.$t("tableContent_3"),
          content_4: {
            text: "",
            copy: true,
          },
          content_5: "10",
        },
        {
          column_title: "SPF",
          content_1: "",
          content_2: "TXT",
          content_3: this.$t("tableContent_3"),
          content_4: {
            text: "",
            copy: true,
          },
          content_5: this.$t("tableContent_3"),
        },
      ],
      // 正在验证
      isVerifying: false,
      domain_failedContents: [
        {
          title: this.$t("dns_failedContent_title_1"),
          content: this.$t("dns_failedContent_content_1"),
        },
        {
          title: this.$t("dns_failedContent_title_2"),
          content: this.$t("dns_failedContent_content_2"),
        },
        {
          title: this.$t("dns_failedContent_title_3"),
          content: this.$t("dns_failedContent_content_3"),
        },
      ],
      validationFailed: false,
      verifyTimer: null,
      disabled: false,
    };
  },
  async mounted() {
    if (this.domainInfos.length > 0) {
      this.tableData = this.tableData.map((data, index) => {
        if (index == 2) {
          data.content_1.text = this.domainInfos[index].hostName;
        } else {
          data.content_1 = this.domainInfos[index].hostName;
        }
        data.content_2 = this.domainInfos[index].type;
        data.content_4.text = this.domainInfos[index].info;
        return data;
      });
    }
    if (
      this.showStep != this.actualCurrentStep &&
      this.completedSteps.includes(this.showStep)
    ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  },
  beforeDestroy() {
    if (this.verifyTimer) {
      clearTimeout(this.verifyTimer);
    }
  },
  methods: {
    async stopVerify() {
      if (this.disabled) {
        return;
      }
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("abandonDomainTips"),
        showDialog: true,
        confirmFn: async () => {
          const result = await abandonVerificationDomain();
          if (result.code == 200) {
            this.$emit("abandonVerification");
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        },
      });
    },
    startVerify() {
      if (this.disabled) {
        return;
      }
      if (this.isVerifying) {
        return;
      }
      this.isVerifying = true;
      this.verifyTimer = setTimeout(async () => {
        const result = await verificationDomain();
        this.isVerifying = false;
        if (result.code == 200) {
          if (result.data.data.verification) {
            if (this.method == 0) {
              this.$message({
                customClass: "meetingMessage",
                dangerouslyUseHTMLString: true,
                message:
                  '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
                  '<div class="copySucceeded"></div>' +
                  "<span>" +
                  this.$t("verificationSuccess") +
                  "</span>" +
                  "</div>",
                duration: 1000,
                onClose: () => {
                  this.$emit("confirmCompletion");
                },
              });
            } else {
              this.$emit("confirmCompletion");
            }
          } else {
            this.validationFailed = true;
            this.$nextTick(() => {
              this.$emit("scrollToTheBottom");
            });
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }, 3000);
    },
    setcellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          "word-break": "break-word",
          "background-color": "#F5F5F5",
          color: "#858585",
          "font-size": "14px",
        };
      }
      return { "word-break": "break-word" };
    },
    // 点击阅读TXT验证
    readDNSverification() {
      this.$emit("readDNSManual");
    },
    // 点击复制按钮
    startCopy(text) {
      this.$copyText(text).then(
        (res) => {
          this.$message({
            customClass: "meetingMessage",
            dangerouslyUseHTMLString: true,
            message:
              '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
              '<div class="copySucceeded"></div>' +
              "<span>" +
              this.$t("copySucceeded") +
              "</span>" +
              "</div>",
            duration: 1000,
          });
        },
        (err) => {
          this.$message({
            type: "error",
            message: this.$t("copyFailed"),
          });
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.verifyDNSContent
  width: 100%;
  overflow: hidden;
  padding-top: 30px;
  .contentHeader
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
    .specialStyle
      color: #52BD68;
      cursor: pointer;
  .contentText
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    word-break: break-word;
  .contentTable
    margin-top: 35px;
    width: 100%;
    min-height: 97px;
    overflow: hidden;
  .dividerLine
    height: 1px;
    width: 100%;
    background: #F0F0F0;
    margin: 32px 0;
  .buttonBox
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .buttonStyle
      height: 100%;
      min-width: 240px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      padding: 0 20px;
      user-select: none;
      &:nth-child(2)
        margin-left: 16px;
    .startVerify
      background: #52BD68;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: min-width 0.3s;
      .wiatingText
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      .wiatingImg
        width: 20px;
        height: 20px;
        animation: rotate 1s linear infinite;
    .cancelVerify
      background: #FFFFFF;
      border: 1px solid #52BD68;
      color: #52BD68;
  .failedBlock
    margin-top: 30px;
    width: 100%;
    min-height: 220px;
    border-radius: 6px;
    border: 1px solid #ED6868;
    background: rgba(237, 104, 104, 0.05);
    padding-bottom: 11px;
    .failedHeader
      height: 24px;
      margin-top: 24px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .failedIcon
        width: 24px;
        height: 24px;
        vertical-align: middle;
      .failedText
        padding-left: 12px;
        color: #FF0000;
        font-size: 16px;
        line-height: 24px;
        vertical-align: middle;
    .failedContent
      padding: 12px 60px 0 60px;
      width: 100%;
      overflow: hidden;
      .failedItem
        width: 100%;
        overflow: hidden;
        .failedTitle
          font-size: 16px;
          color: #000000;
        .failedText
          font-size: 14px;
          color: #858585;
          margin: 8px 0 24px 0;
          word-break: break-word;
          padding-left: 25px;
</style>
