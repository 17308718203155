var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "verifyDomainContent" }, [
    _c("div", { staticClass: "contentHeader" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("verifyContent_1", { n: _vm.domainText })) +
          "\n    "
      ),
      _c(
        "span",
        {
          staticClass: "specialStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.readTXTverification.apply(null, arguments)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("TXTVerification")))]
      ),
      _vm._v(":\n  ")
    ]),
    _c("div", { staticClass: "contentText" }, [
      _vm._v(
        "\n    1、" +
          _vm._s(_vm.$t("verifyContent_2", { n: _vm.domainText })) +
          "\n  "
      )
    ]),
    _c("div", { staticClass: "contentText" }, [
      _vm._v(
        "\n    2、" +
          _vm._s(_vm.$t("verifyContent_3", { n: _vm.domainText })) +
          "\n  "
      )
    ]),
    _c(
      "div",
      { staticClass: "contentTable" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "header-cell-style": {
                background: "#F5F5F5",
                "font-weight": "400",
                color: "#858585",
                "font-size": "14px"
              },
              "row-style": {
                color: "#000000",
                "font-size": "14px"
              },
              "cell-style": {
                "word-break": "break-word"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "content_1",
                label: _vm.$t("tableHeader_1"),
                width: "180",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_2",
                label: _vm.$t("tableHeader_2"),
                width: "90",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_3",
                label: _vm.$t("tableHeader_3"),
                width: "90",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_4",
                label: _vm.$t("tableHeader_4"),
                align: "center",
                resizable: false
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          style: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("div", { style: { "word-break": "break-word" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.content_4) +
                                "\n            "
                            )
                          ]),
                          _c("i", {
                            staticClass: "el-icon-document-copy",
                            style: {
                              color: "#52bd68",
                              "font-size": "18px",
                              "margin-left": "6px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.startCopy(row.content_4)
                              }
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "contentText" }, [
      _vm._v("3、" + _vm._s(_vm.$t("verifyContent_4")))
    ]),
    _c("div", { staticClass: "dividerLine" }),
    !_vm.disabled
      ? _c("div", { staticClass: "buttonBox" }, [
          _c(
            "div",
            {
              staticClass: "buttonStyle startVerify",
              style: {
                "min-width": _vm.isVerifying ? "496px" : "240px"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startVerify.apply(null, arguments)
                }
              }
            },
            [
              !_vm.isVerifying
                ? [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("startVerify")) + "\n      "
                    )
                  ]
                : [
                    _c("div", { staticClass: "wiatingText" }, [
                      _vm._v(_vm._s(_vm.$t("nowVerifying")))
                    ]),
                    _c("img", {
                      staticClass: "wiatingImg",
                      attrs: {
                        src: require("../../../../../assets/images/teamManagement/refresh.png")
                      }
                    })
                  ]
            ],
            2
          ),
          !_vm.isVerifying
            ? _c(
                "div",
                {
                  staticClass: "buttonStyle cancelVerify",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.stopVerify.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("stopVerifiy")) + "\n    ")]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm.validationFailed
      ? _c("div", { staticClass: "failedBlock" }, [
          _c("div", { staticClass: "failedHeader" }, [
            _c("img", {
              staticClass: "failedIcon",
              attrs: {
                src: require("../../../../../assets/images/teamManagement/failed.png")
              }
            }),
            _c("div", { staticClass: "failedText" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("failedTitle")) + "\n      ")
            ])
          ]),
          _c(
            "div",
            { staticClass: "failedContent" },
            [
              _vm._l(_vm.domain_failedContents, function(item, index) {
                return [
                  _c("div", { key: index, staticClass: "failedItem" }, [
                    _c("div", { staticClass: "failedTitle" }, [
                      _c(
                        "span",
                        { style: { display: "inline-block", width: "25px" } },
                        [_vm._v(_vm._s(index + 1) + "、")]
                      ),
                      _vm._v(_vm._s(item.title) + "\n          ")
                    ]),
                    _c("div", { staticClass: "failedText" }, [
                      _vm._v(
                        "\n            " + _vm._s(item.content) + "\n          "
                      )
                    ])
                  ])
                ]
              })
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }