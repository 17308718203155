<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :top="'0'"
    z-index="1000"
    :custom-class="customClass"
    :destroy-on-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @before-close="beforeClose"
    v-loading="dialogLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255,255,255,0.3)"
  >
    <template>
      <div class="initialHeader" v-if="showBlockStep == 0">
        <i class="iconStyle iconfont_Me icon-x" @click.stop="close"></i>
      </div>
      <div class="methodSelectionHeader" v-if="showBlockStep == 1">
        <i class="iconStyle iconfont_Me icon-x" @click.stop="close"></i>
      </div>
      <div class="stepHeader" v-if="showBlockStep == 2">
        <div class="title">
          {{
            teamEmailActivationMethod == 0
              ? $t("domainVerification")
              : $t("mailboxMigrate")
          }}——{{ domainText }}
        </div>
        <div class="headerOperate">
          <div
            class="buttonOuter"
            @click.stop="changeSize(1)"
            v-if="sizeType == 0"
          >
            <SizeButton></SizeButton>
          </div>
          <div
            class="buttonOuter"
            @click.stop="changeSize(0)"
            v-if="sizeType == 1"
          >
            <SizeButton :buttonType="'full'"></SizeButton>
          </div>
          <div
            @click.stop="close"
            class="icon-x iconfont_Me iconStyle buttonOuter"
          ></div>
        </div>
      </div>
    </template>
    <div
      class="initialContent"
      v-if="showBlockStep == 0"
      v-loading="domainLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,0.3)"
    >
      <div class="title">
        {{ $t("addDomain") }}
      </div>
      <div class="content">
        <el-input
          v-model="domainText"
          :class="domainVerifyFailed ? 'errorInput' : ''"
        >
          <template slot="prepend">
            <img
              :src="
                require('../../../../assets/images/teamManagement/icon_domain.png')
              "
              :style="{
                width: '28px',
                height: '28px',
              }"
            />
          </template>
        </el-input>
        <div class="failedText" v-if="domainVerifyFailed">
          {{
            domainTips == 0
              ? $t("domainVerificationFailed")
              : domainTips == 1
              ? $t("domainErrorText")
              : domainTips == 2
              ? $t("domainErrorText_1")
              : ""
          }}
        </div>
      </div>
      <div class="footer">
        <el-button
          type="primary"
          @click.stop="goMethodSelection"
          :disabled="initial_disabled"
          :style="{
            background: initial_disabled ? '#E5E5E5' : '',
            border: initial_disabled ? '1px solid #E5E5E5' : '',
          }"
        >
          <i class="iconfont_Me icon-check iconStyle"></i>
        </el-button>
      </div>
    </div>
    <div
      class="methodSelectionContent"
      v-if="showBlockStep == 1"
      v-loading="initialLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,0.3)"
    >
      <span class="tipsText">{{
        $t("methodSelectionTips", { n: domainText })
      }}</span>
      <div class="buttonBox">
        <div
          class="buttonStyle cancellation"
          v-waves
          @click.stop="methodSelectionFn(0)"
        >
          <i class="iconStyle iconfont_Me icon-x"></i>
        </div>
        <div
          class="buttonStyle confirm"
          v-waves
          @click.stop="methodSelectionFn(1)"
        >
          <i class="iconStyle iconfont_Me icon-check"></i>
        </div>
      </div>
    </div>
    <div
      class="stepContent"
      ref="stepContent"
      v-if="showBlockStep == 2"
      :style="{ 'overflow-y': expandManual ? 'hidden' : 'auto' }"
    >
      <div class="manualBlock" v-if="expandManual">
        <div class="innerContent">
          <div class="headerBlock">
            <i
              class="iconfont_Me icon-return iconStyle"
              @click.stop="closeManual"
            />
          </div>
          <TXTManual v-show="expandTXTManual" />
          <DNSManual v-show="expandDNSManual" />
        </div>
      </div>
      <div class="stepBarBox">
        <StepBar
          :steps="handlerViews"
          :showStep="showStep"
          :active="actualCurrentStep"
          :completedSteps="completedSteps"
          :incompleteSteps="incompleteSteps"
          @selectStep="selectStep"
        />
      </div>
      <div class="contentBox" v-if="calculateView">
        <component
          :is="calculateView"
          :showStep="showStep"
          :onceAgain="onceAgain"
          :actualCurrentStep="actualCurrentStep"
          :completedSteps="completedSteps"
          :domainText="domainText"
          :domainInfos="domainInfos"
          :method="teamEmailActivationMethod"
          @readTXTManual="readTXTManual"
          @readDNSManual="readDNSManual"
          @confirmCompletion="confirmCompletion"
          @scrollToTheBottom="scrollToTheBottom"
          @abandonVerification="abandonVerification"
          @close="close"
        />
      </div>
    </div>
  </el-dialog>
</template>
<script>
import SizeButton from "@/newVersion/components/im_size_button";
import DomainOwnershipVerification from "./verifyDomainDialog_content/domainOwnershipVerification.vue";
import ValidateDNSConfiguration from "./verifyDomainDialog_content/validateDNSConfiguration.vue";
import ConfigureEmailMigrationParams from "./verifyDomainDialog_content/configureEmailMigrationParams.vue";
import CreateMoveMemberEmailAccount from "./verifyDomainDialog_content/createMoveMemberEmailAccount.vue";
import EnableMemberEmailMigration from "./verifyDomainDialog_content/enableMemberEmailMigration.vue";
import ReplaceOrCancelDomainByOriginalServiceProvider from "./verifyDomainDialog_content/replaceOrCancelDomainByOriginalServiceProvider.vue";
import TXTManual from "./verifyDomainDialog_content/TXTManual.vue";
import DNSManual from "./verifyDomainDialog_content/DNSManual.vue";
import StepBar from "./verifyDomainDialog_content/stepBar.vue";
import {
  domainExists,
  createDomain,
  getDomainRecord,
  getTeamInfo,
} from "@/api/newVersion/team";
import isFQDN from "validator/lib/isFQDN";
export default {
  name: "verifyDomainDialog",
  components: {
    SizeButton,
    DomainOwnershipVerification,
    ValidateDNSConfiguration,
    ConfigureEmailMigrationParams,
    CreateMoveMemberEmailAccount,
    EnableMemberEmailMigration,
    ReplaceOrCancelDomainByOriginalServiceProvider,
    TXTManual,
    DNSManual,
    StepBar,
  },
  data() {
    return {
      // 当前弹窗进行的步骤
      showBlockStep: 0,
      // 域名
      domainText: "",
      // 验证失败
      domainVerifyFailed: false,
      // 窗口大小
      sizeType: 0,
      // 当前验证进度
      active: 0,
      expandTXTManual: false,
      expandDNSManual: false,
      domainTips: 0,
      domainLoading: false,
      initialLoading: false,
      // 所有步骤
      views: [
        {
          // 域名所属权验证
          step: 3,
          name: "domainOwnershipVerification",
          component: "DomainOwnershipVerification",
          title: this.$t("verifyTheOwnershipOfTheDomain"),
        },
        {
          // 配置迁移参数
          step: 4,
          name: "configureEmailMigrationParams",
          component: "ConfigureEmailMigrationParams",
          title: this.$t("configureEmailMigrationParams"),
        },
        {
          // 创建搬家成员邮箱账号
          step: 5,
          name: "createMoveMemberEmailAccount",
          component: "CreateMoveMemberEmailAccount",
          title: this.$t("createMoveMemberEmailAccount"),
        },
        {
          // 验证DNS配置
          step: 6,
          name: "validateDNSConfiguration",
          component: "ValidateDNSConfiguration",
          title: this.$t("verifyDNSConfig"),
        },
        {
          // 开启成员邮箱迁移功能
          step: 7,
          name: "enableMemberEmailMigration",
          component: "EnableMemberEmailMigration",
          title: this.$t("enableMemberEmailMigration"),
        },
        {
          // 在原服务商替换或注销域名
          step: 8,
          name: "replaceOrCancelDomainByOriginalServiceProvider",
          component: "ReplaceOrCancelDomainByOriginalServiceProvider",
          title: this.$t("replaceOrCancelDomainByOriginalServiceProvider"),
        },
      ],
      // 团队邮箱启用方式
      teamEmailActivationMethod: 0,
      // 当前实际步骤
      actualCurrentStep: 0,
      // 已完成的步骤
      completedSteps: [],
      // 未完成的步骤
      incompleteSteps: [],
      // 当前展示步骤
      showStep: 0,
      domainRecord: [],
      dialogLoading: false,
    };
  },
  computed: {
    customClass() {
      return this.showBlockStep == 0
        ? "teamVerifyDomainDialog verifyDomain_initialStyle"
        : this.showBlockStep == 1
        ? "teamVerifyDomainDialog verifyDomain_methodSelection"
        : this.sizeType == 0
        ? "teamVerifyDomainDialog verifyDomain_stepStyle"
        : "teamVerifyDomainDialog verifyDomain_stepStyle_large";
    },
    dialogVisible() {
      return !!this.$store.state.team.verifyDomainDialog;
    },
    verifyStep() {
      return this.$store.state.team.verifyDomainDialog.verifyStep;
    },
    migrate() {
      return this.$store.state.team.verifyDomainDialog.migrate;
    },
    binaryHighBitRecords() {
      return this.$store.state.team.verifyDomainDialog.binaryHighBitRecords;
    },
    domain() {
      let domain = "";
      if (this.$store.state.team.verifyDomainDialog) {
        domain = this.$store.state.team.verifyDomainDialog.domain;
      }
      return domain;
    },
    initial_disabled() {
      return this.domainText == "" || this.domainVerifyFailed;
    },
    expandManual() {
      return this.expandTXTManual || this.expandDNSManual;
    },
    // 根据激活方式，显示不同的步骤
    handlerViews() {
      let handlerViews = [];
      switch (this.teamEmailActivationMethod) {
        case 0:
          handlerViews = [
            { ...this.views[0], index: 0 },
            { ...this.views[3], index: 1 },
          ];
          break;
        case 1:
          if (this.onceAgain) {
            const steps = [4, 7, 8];
            handlerViews = _.cloneDeep(this.views)
              .filter((item) => {
                return steps.includes(item.step);
              })
              .map((item, index) => {
                item.index = index;
                return item;
              });
          } else {
            handlerViews = _.cloneDeep(this.views).map((item, index) => {
              item.index = index;
              return item;
            });
          }
          break;
        default:
          break;
      }
      return handlerViews;
    },
    calculateView() {
      const view = this.handlerViews.filter(
        (item) => item.step == this.showStep
      )[0];
      if (view) {
        return view.component;
      } else {
        return null;
      }
    },
    domainInfos() {
      return this.domainRecord.filter(
        (item) => item.registerStep == this.showStep
      );
    },
    // 判断是否再次进入企业邮箱迁移
    onceAgain() {
      if (typeof this.binaryHighBitRecords == "string") {
        return this.binaryHighBitRecords.substr(2, 2) == "10";
      } else {
        return false;
      }
    },
  },
  watch: {
    domainText: {
      handler(val, old) {
        if (val != old) {
          this.domainVerifyFailed = false;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.dialogLoading = true;
    // 设置激活方式
    this.teamEmailActivationMethod = this.migrate || this.onceAgain ? 1 : 0;
    // 获取域名配置
    await this.getDomainRecord();
    this.dialogLoading = false;
    this.showBlockStep = 0;
    if (this.$store.state.team.verifyDomainDialog.verifyStep) {
      this.handlersteps();
    }
  },
  methods: {
    async selectStep(step) {
      // 如果点击的是未完成的步骤并且不是当前步骤，就直接返回
      if (
        this.incompleteSteps.includes(step) &&
        step != this.actualCurrentStep
      ) {
        return;
      }
      this.showStep = step;
    },
    // 开始计算当前进行的步骤
    async handlersteps() {
      this.completedSteps = this.verifyStep.completedSteps;
      this.incompleteSteps = this.verifyStep.incompleteSteps;
      let currentStep = 0;
      // 判断当前步骤是否完成如果完成的话，就把当前步骤向前移动一个步骤
      if (
        this.completedSteps.includes(this.verifyStep.currentStep) ||
        this.verifyStep.currentStep == 0
      ) {
        // 如果是走的第一种方式，并且已经完成了域名验证，则直接跳到DNS配置界面
        if (
          this.verifyStep.currentStep == 3 &&
          this.teamEmailActivationMethod == 0
        ) {
          currentStep = 6;
        } else if (this.onceAgain) {
          let minStep = Math.min(...this.incompleteSteps);
          if (this.verifyStep.currentStep == 6 && minStep == 4) {
            currentStep = 4;
          } else if (this.verifyStep.currentStep == 4) {
            currentStep = 7;
          } else if (this.verifyStep.currentStep == 7) {
            currentStep = 8;
          }
        } else {
          currentStep = this.verifyStep.currentStep + 1;
        }
      }
      // 当前步骤实际进度
      this.actualCurrentStep = currentStep;
      // 默认展示当前进度步骤
      this.showStep = currentStep;
      switch (this.actualCurrentStep) {
        case 1:
          this.showBlockStep = 0;
          break;
        case 2:
          this.showBlockStep = 1;
          this.domainText = this.domain;
          break;
        default:
          this.showBlockStep = 2;
          this.domainText = this.domain;
          break;
      }
    },
    // 完成操作
    async confirmCompletion() {
      this.$store.commit("setRefreshTeamInfo", true);
      await this.getTeamInfo();
      // 根据用户选择方式来判断后续操作
      if (
        (this.teamEmailActivationMethod == 0 &&
          this.showStep == 6 &&
          this.actualCurrentStep == 6) ||
        (this.teamEmailActivationMethod == 1 &&
          this.showStep == 8 &&
          this.actualCurrentStep == 8)
      ) {
        this.close();
      } else {
        this.handlersteps();
      }
    },
    // 滚动到底部
    scrollToTheBottom() {
      try {
        this.$refs.stepContent.scrollTop = this.$refs.stepContent.scrollHeight;
      } catch (error) {}
    },
    // 放弃验证
    abandonVerification() {
      this.$store.commit("setRefreshTeamInfo", true);
      this.close();
    },
    // 获取域名记录
    async getDomainRecord() {
      const result = await getDomainRecord();
      if (result.code == 200) {
        if (typeof result.data.data != "string") {
          this.domainRecord = result.data.data;
        } else {
          this.domainRecord = [];
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    beforeClose() {
      this.close();
    },
    changeSize(type) {
      this.sizeType = type;
    },
    close() {
      this.$store.commit("setVerifyDomainDialog", null);
    },
    // 选择开启方式
    async methodSelectionFn(step) {
      this.initialLoading = true;
      this.teamEmailActivationMethod = step;
      try {
        // 完成步骤
        let data = {
          domain: this.domainText,
          migrate: step == 0 ? false : true,
        };
        const result_0 = await createDomain(data);
        if (result_0.code == 200) {
          // 获取域名相关配置信息
          await this.getDomainRecord();
          this.$store.commit("setRefreshTeamInfo", true);
          // 更新当前步骤
          let verifyStep = this.$store.state.team.verifyDomainDialog.verifyStep;
          verifyStep.currentStep = 2;
          verifyStep.completedSteps.push(2);
          verifyStep.incompleteSteps = verifyStep.incompleteSteps.filter(
            (item) => item != 2
          );
          this.$store.state.team.verifyDomainDialog.domain = this.domainText;
          this.$store.state.team.verifyDomainDialog.migrate =
            step == 0 ? false : true;
          this.processDecimal(50);
          // 设置新步骤以及刷新激活方式
          this.handlersteps();
          this.initialLoading = false;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          this.initialLoading = false;
        }
      } catch (error) {
        this.domainVerifyFailed = true;
        this.initialLoading = false;
      }
    },
    async getTeamInfo(
      params = {
        pageSize: 1,
        page: 1,
        searchWords: "",
      }
    ) {
      const result = await getTeamInfo(params);
      if (result.code == 200) {
        let { mailDomain, mailRegisterStep, migrate } = result.data.data;
        mailRegisterStep = this.processDecimal(mailRegisterStep);
        this.$store.commit("setVerifyDomainDialog", {
          verifyStep: mailRegisterStep,
          domain: mailDomain,
          migrate: migrate,
          binaryHighBitRecords: this.binaryHighBitRecords,
        });
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
    // 处理步骤二进制
    processDecimal(decimalNumber) {
      let binaryString = decimalNumber.toString(2).padStart(12, "0");
      let currentStep = parseInt(binaryString.slice(-4), 2);
      this.$store.state.team.verifyDomainDialog.binaryHighBitRecords = binaryString.slice(
        0,
        -4
      );
      let completedSteps = [];
      let incompleteSteps = [];
      for (let i = binaryString.length - 5; i >= 0; i--) {
        if (binaryString[i] === "1") {
          completedSteps.push(binaryString.length - i - 5 + 1);
        } else {
          incompleteSteps.push(binaryString.length - i - 5 + 1);
        }
      }
      return {
        currentStep: currentStep,
        completedSteps: completedSteps,
        incompleteSteps: incompleteSteps,
      };
    },
    // 前往启用方式选择界面
    async goMethodSelection() {
      try {
        this.domainLoading = true;
        if (isFQDN(this.domainText)) {
          this.domainText = this.domainText.replace(/^www\./, "");
          // 判断域名是否存在
          const result = await domainExists(this.domainText);
          if (result.code == 200 && !result.data.data) {
            this.domainLoading = false;
            this.showBlockStep = 1;
          } else {
            this.domainTips = 0;
            throw new Error();
          }
        } else {
          this.domainTips = 1;
          throw new Error();
        }
      } catch (error) {
        this.domainLoading = false;
        this.domainVerifyFailed = true;
      }
    },
    readTXTManual() {
      this.$refs.stepContent.scrollTop = 0;
      this.expandTXTManual = true;
    },
    readDNSManual() {
      this.$refs.stepContent.scrollTop = 0;
      this.expandDNSManual = true;
    },
    closeManual() {
      this.expandTXTManual = false;
      this.expandDNSManual = false;
    },
  },
};
</script>
<style lang="stylus">
.teamVerifyDomainDialog
  margin: 0 !important;
  .el-dialog__footer
    display: none;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .initialHeader
      width: 100%;
      height: 30px;
      position: relative;
      flex-shrink: 0;
      .iconStyle
        font-size: 20px;
        position: absolute;
        top: 18px;
        right: 18px;
        color #858585;
        cursor: pointer;
    .initialContent
      width: 100%;
      min-height: 0;
      flex: 1;
      padding: 10px 80px 50px 80px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      .title
        color: #292D32;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        line-height: 34px;
        height: 34px;
        flex-shrink: 0;
        width: 100%;
      .content
        flex: 1;
        width: 100%;
        min-height: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .el-input
          margin-top: 100px;
          .el-input-group__prepend
            img
              vertical-align: middle;
        .errorInput
          border: 1px solid #FF0000 !important;
          border-radius: 4px;
          .el-input-group__prepend
            border: none !important;
          .el-input__inner
            border: none;
        .failedText
          width: 100%;
          min-height: 22px;
          line-height: 22px;
          text-align: left;
          font-size: 16px;
          color: #FF0000;
          margin-top: 12px;
      .footer
        width: 100%;
        height: 48px;
        .el-button
          width: 100%;
          height: 100%;
          border-radius: 4px;
          color: #FFFFFF;
          .iconStyle
            font-size: 24px;
    .methodSelectionHeader
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #F0F0F0;
      flex-shrink: 0;
      position: relative;
      .iconStyle
        font-size: 20px;
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translate3d(0,-50%,0)
        color #858585;
        cursor: pointer;
    .methodSelectionContent
      width: 100%;
      min-height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .tipsText
        color: #292D32;
        font-size: 20px;
        line-height: 28px;
      .buttonBox
        width: 100%;
        height: 50px;
        margin-top: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        .buttonStyle
          height: 100%;
          width: 240px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        .cancellation
          border: 1px solid #52BD68;
          .iconStyle
            font-size: 20px;
            color: #52BD68;
        .confirm
          background-color: #52BD68;
          margin-left: 20px;
          .iconStyle
            font-size: 20px;
            color: #FFFFFF;
    .stepHeader
      width: 100%;
      height: 5vw;
      max-height: 96px;
      min-height: 67px;
      border-bottom: 1px solid #F0F0F0;
      padding-left: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .title
        font-size: 20px;
        color: #292D32;
        font-weight: 600;
      .headerOperate
        position: absolute;
        color: #292D32;
        width: 50px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        top: 15px;
        right: 30px;
        .buttonOuter
          height: 16px;
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        .iconStyle
          font-size: 16px;
    .stepContent
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      &::-webkit-scrollbar
        width: 6px;
      &::-webkit-scrollbar-thumb
        border-radius: 3px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
        background: #D9D9D9;
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
      .manualBlock
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar
          width: 6px;
        &::-webkit-scrollbar-thumb
          border-radius: 3px;
          box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
          background: #D9D9D9;
        &::-webkit-scrollbar-track
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
          border-radius: 0;
          background: rgba(0, 0, 0, 0);
        .innerContent
          width: 900px;
          margin: auto;
          min-height: 0;
          overflow: hidden;
          padding-bottom: 30px;
          .headerBlock
            width: 100%;
            height: 75px;
            padding: 20px 0 30px 0;
            text-align: left;
            .iconStyle
              font-size: 20px;
              color: #858585;
              vertical-align: middle;
              line-height: 25px;
              cursor: pointer;
      .stepBarBox
        width: 100%;
        min-height: 48px;
        margin-top: 30px;
        display: flex;
        overflow: hidden;
        &::-webkit-scrollbar {
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
          background: rgba(100,100,100,0.2);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          border-radius: 0;
          background: rgba(0,0,0,0);
        }
        background: #F5F5F5;
        padding: 10px 20px;
      .contentBox
        width: 900px;
        margin: auto;
        min-height: 0;
        overflow: hidden;
        padding-bottom: 30px;
.verifyDomain_initialStyle
  width: 33.333vw;
  height: 23.958vw;
  max-width: 640px;
  max-height: 460px;
  min-width: 640px;
  min-height: 460px;
.verifyDomain_methodSelection
  width: 46vw;
  height: 21vw;
  max-width: 900px;
  max-height: 413px;
  min-width: 600px;
  min-height: 275px;
.verifyDomain_stepStyle
  width: 75vw;
  height: 39vw;
  max-width: 1440px;
  max-height: 750px;
  min-width: 1000px;
  min-height: 520px;
.verifyDomain_stepStyle_large
  width: 100vw;
  height: 100vh;
  max-width: 10000px;
  max-height: 10000px;
  min-width: 0px;
  min-height: 0px;
@keyframes rotate {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}
</style>
