var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "configureEmailMigrationParams" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("configureEmailMigrationParams_title_1")) + ":")
    ]),
    _c("div", { staticClass: "table_style table_1" }, [
      _c("div", { staticClass: "table_row" }, [
        _c("div", { staticClass: "row_title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("originalEmailServiceProvider")) +
              "\n      "
          )
        ]),
        _c(
          "div",
          { staticClass: "row_content" },
          [
            _c(
              "el-select",
              {
                attrs: { disabled: _vm.disabled },
                on: { change: _vm.changeServiceName },
                model: {
                  value: _vm.formData.serviceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "serviceName", $$v)
                  },
                  expression: "formData.serviceName"
                }
              },
              _vm._l(_vm.selectOptions, function(item) {
                return _c("el-option", {
                  key: item.mailServer,
                  attrs: { label: item.serviceName, value: item.serviceName }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table_row" }, [
        _c("div", { staticClass: "row_title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("originalEmailLinkAgreement")) +
              "\n      "
          )
        ]),
        _c(
          "div",
          { staticClass: "row_content" },
          [
            _c("el-input", {
              attrs: { readonly: "" },
              model: {
                value: _vm.formData.protocol,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "protocol", $$v)
                },
                expression: "formData.protocol"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table_row" }, [
        _c("div", { staticClass: "row_title" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("incomingMailServer")) + "\n      "
          )
        ]),
        _c("div", { staticClass: "row_content" }, [
          _c(
            "div",
            { staticClass: "row_input" },
            [
              _c("el-input", {
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.formData.mailServer,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mailServer", $$v)
                  },
                  expression: "formData.mailServer"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "row_inner_content" }, [
            _c("div", { staticClass: "inner_title" }, [
              _vm._v("\n            " + _vm._s(_vm.$t("port")) + "\n          ")
            ]),
            _c(
              "div",
              { staticClass: "inner_input" },
              [
                _c("el-input", {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.formData.port,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "port", $$v)
                    },
                    expression: "formData.port"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "title" }, [
      _c(
        "span",
        {
          staticClass: "clickBlock",
          style: { cursor: _vm.disabled ? "not-allowed" : "pointer" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.changeSSL.apply(null, arguments)
            }
          }
        },
        [
          _c("i", { staticClass: "icon" }, [
            _vm.useSSL
              ? _c("img", {
                  staticClass: "img_1",
                  style: { cursor: _vm.disabled ? "not-allowed" : "pointer" },
                  attrs: {
                    src: require("../../../../../assets/images/teamManagement/SSL_icon.png")
                  }
                })
              : _c("img", {
                  staticClass: "img_2",
                  style: { cursor: _vm.disabled ? "not-allowed" : "pointer" },
                  attrs: {
                    src: require("../../../../../assets/images/teamManagement/no_SSL_icon.png")
                  }
                })
          ]),
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("configureEmailMigrationParams_title_2")) +
              "\n    "
          )
        ]
      )
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("configureEmailMigrationParams_title_3")) + ":")
    ]),
    _c("div", { staticClass: "table_style table_2" }, [
      _c("div", { staticClass: "table_row" }, [
        _c("div", { staticClass: "row_title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("configureEmailMigrationParams_title_4")) +
              "\n      "
          )
        ]),
        _c(
          "div",
          { staticClass: "row_content" },
          [
            _c(
              "el-input",
              {
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.originalAccount,
                  callback: function($$v) {
                    _vm.originalAccount = $$v
                  },
                  expression: "originalAccount"
                }
              },
              [
                _c("template", { slot: "append" }, [
                  _vm._v(
                    "\n            @" +
                      _vm._s(_vm.$store.state.team.verifyDomainDialog.domain) +
                      "\n          "
                  )
                ])
              ],
              2
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table_row" }, [
        _c("div", { staticClass: "row_title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("configureEmailMigrationParams_title_5")) +
              "\n      "
          )
        ]),
        _c(
          "div",
          { staticClass: "row_content" },
          [
            _c("el-input", {
              attrs: { disabled: _vm.disabled, "show-password": "" },
              model: {
                value: _vm.originalPassword,
                callback: function($$v) {
                  _vm.originalPassword = $$v
                },
                expression: "originalPassword"
              }
            })
          ],
          1
        )
      ])
    ]),
    !_vm.disabled
      ? _c("div", { staticClass: "button_box" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "button_style",
              style: {
                cursor: _vm.canStartVerification ? "pointer" : "not-allowed",
                background: _vm.canStartVerification ? "#52BD68" : "#D9D9D9"
              },
              on: { click: _vm.startVerification }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("startVerification")) + "\n    "
              )
            ]
          ),
          !_vm.onceAgain
            ? _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "button_style r_button",
                  on: { click: _vm.stopMigration }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("stopMigration")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm.validationFailed
      ? _c("div", { staticClass: "failedBlock" }, [
          _c("div", { staticClass: "failedHeader" }, [
            _c("img", {
              staticClass: "failedIcon",
              attrs: {
                src: require("../../../../../assets/images/teamManagement/failed.png")
              }
            }),
            _c("div", { staticClass: "failedText" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("configureEmailMigrationParams_failed_title")) +
                  "\n      "
              )
            ])
          ]),
          _c("div", { staticClass: "failedContent" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("configureEmailMigrationParams_failed_content")) +
                "\n    "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }