<template>
  <div class="enableMemberEmailMigration">
    <div class="content_1">
      {{ $t("enableMemberEmailMigration_content_1", { n: domainText }) }}
    </div>
    <div class="content_2">
      {{ $t("enableMemberEmailMigration_content_2") }}
    </div>
    <div class="content_2">
      {{ $t("enableMemberEmailMigration_content_3") }}
    </div>
    <div class="content_box">
      <div class="box">
        <el-image
          style="width: 100%; height: 100%"
          :src="require('../../../../../assets/images/teamManagement/test.png')"
        ></el-image>
      </div>
    </div>
    <div class="button_box" v-if="!disabled">
      <div class="button_style" v-waves @click.stop="nextStep">
        {{ $t("createMoveMemberEmailAccount_button_r") }}
      </div>
    </div>
  </div>
</template>
<script>
import { setStep } from "@/api/newVersion/team";
export default {
  name: "EnableMemberEmailMigration",
  data() {
    return {
      disabled: false,
    };
  },
  props: {
    domainText: {
      type: String,
      default: "",
    },
    // 当前流程进度
    actualCurrentStep: {
      type: Number,
      default: 0,
    },
    // 当前模块所属步骤
    showStep: {
      type: Number,
      default: 0,
    },
    // 已完成步骤
    completedSteps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    if (
      this.showStep != this.actualCurrentStep &&
      this.completedSteps.includes(this.showStep)
    ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  },
  methods: {
    async nextStep() {
      if (this.disabled) {
        return;
      }
      const result = await setStep({
        step: "7",
        migrate: true,
      });
      if (result.code == 200 && result.data.data) {
        this.$emit("confirmCompletion");
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.enableMemberEmailMigration
  width: 100%;
  overflow: hidden;
  padding-top: 80px
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  .content_1
    width: 100%;
    font-size: 16px;
    color: #000;
    margin-bottom: 1.538vw;
    text-align: left;
    line-height: 24px;
  .content_2
    width: 100%;
    font-size: 16px;
    color: #000;
    text-align: left;
    line-height: 24px;
  .content_box
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 30px 0 40px 0;
    .box
      width: 880px;
      background: #D9D9D9;
  .button_box
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    .button_style
      height: 100%;
      width: 340px;
      background: #52BD68;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      cursor: pointer;
      user-select: none;
</style>
